var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',{staticClass:"fill-height"},[_c('TheAlerts'),_c('v-content',[_c('v-container',{attrs:{"fill-height":""}},[_c('v-layout',{attrs:{"align-center":"","column":"","justify-center":""}},[_c('v-form',{ref:"forgot",staticStyle:{"margin-bottom":"100px"}},[_c('v-layout',{attrs:{"column":"","align-center":"","justify-center":""}},[_c('v-flex',[_c('h1',[_vm._v("Forgot Password")])]),_c('v-layout',{staticClass:"forgot_form sheet elevation-1",attrs:{"column":""}},[_c('CRInput',{attrs:{"id":"forgotPassword","type":"text","placeholder":"","rules":[
                  _vm.isRequired(true, _vm.validateEmail, {
                    req: 'Email Required',
                    error: 'Email required and must be valid',
                  }),
                ]},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('v-layout',{attrs:{"row":"","align-center":"","justify-center":""}},[_c('div',{staticClass:"text-box"},[_vm._v(" Enter your email above and we'll send you a link to reset your password. ")])]),_c('v-layout',{attrs:{"row":"","align-center":"","justify-center":""}},[_c('v-btn',{staticClass:"btn-secondaryaction",on:{"click":function($event){_vm.$store.dispatch('app/closeDialog') &&
                      _vm.$router.push({ name: 'login' })}}},[_vm._v(" Cancel ")]),_c('v-btn',{staticClass:"btn-primaryaction",attrs:{"id":"forgot-submit-button","disabled":_vm.loading,"loading":_vm.loading},on:{"click":_vm.forgotPassword}},[_vm._v(" Submit ")])],1)],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }