<template>
  <v-app class="fill-height">
    <TheAlerts />
    <v-content>
      <v-container fill-height>
        <v-layout align-center column justify-center>
          <v-form ref="forgot" style="margin-bottom: 100px">
            <v-layout column align-center justify-center>
              <v-flex><h1>Forgot Password</h1></v-flex>

              <v-layout column class="forgot_form sheet elevation-1">
                <CRInput
                  id="forgotPassword"
                  v-model="email"
                  type="text"
                  placeholder=""
                  :rules="[
                    isRequired(true, validateEmail, {
                      req: 'Email Required',
                      error: 'Email required and must be valid',
                    }),
                  ]"
                />

                <v-layout row align-center justify-center>
                  <div class="text-box">
                    Enter your email above and we'll send you a link to reset
                    your password.
                  </div>
                </v-layout>
                <v-layout row align-center justify-center>
                  <v-btn
                    class="btn-secondaryaction"
                    @click="
                      $store.dispatch('app/closeDialog') &&
                        $router.push({ name: 'login' })
                    "
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    id="forgot-submit-button"
                    :disabled="loading"
                    :loading="loading"
                    class="btn-primaryaction"
                    @click="forgotPassword"
                  >
                    Submit
                  </v-btn>
                </v-layout>
              </v-layout>
            </v-layout>
          </v-form>
        </v-layout>
      </v-container>
    </v-content>
  </v-app>
</template>

<script>
import { isRequired, validateEmail } from '@/utils/validators'
import { mapActions } from 'vuex'
import TheAlerts from '@/components/TheAlerts.vue'

export default {
  components: {
    TheAlerts,
  },
  metaInfo() {
    return {
      title: 'Forgot Password',
    }
  },
  data() {
    return {
      email: '',
      loading: false,
    }
  },
  methods: {
    isRequired,
    validateEmail,
    ...mapActions({ showAlert: 'app/showAlert' }),
    ...mapActions({ forgotEmail: 'auth/forgotPassword' }),
    async forgotPassword() {
      this.loading = true
      const valid = this.$refs.forgot.validate()
      if (!valid) {
        this.loading = false
        return
      }

      try {
        await this.forgotEmail({ email: this.email })
        this.showAlert({
          message: 'Email sent! Please check your inbox!',
        })
      } catch (e) {
        this.showAlert({
          message: 'Error!',
          type: 'error',
        })
      }
      this.loading = false
    },
  },
}
</script>

<style lang="scss" scoped>
#app {
  background-image: url('~@/assets/images/icon-bg.svg');
  background-repeat: no-repeat;
  background-position: top;
  background-size: contain;
}

.login-container {
  width: 30%;
  min-width: 300px;
}

.login__logo {
  width: 60%;
  margin: 0 auto 25px auto;
}

.login-prompt {
  margin-top: 30px;
  font-size: 16px;
  text-align: center;

  .phone {
    font-weight: bold;
    text-decoration: none;
  }
}

.forgot_form {
  width: 400px;

  .text-box {
    width: 300px;
    text-align: center;
    margin-bottom: 15px;
  }
}
</style>
